<template>
  <div class="container mb-5">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <div class="row">
            <div class="col-md-3" v-for="sdg in my_sdg" :key="sdg">
                <div class="row">
                    <div class="col-md-6" v-if="sdg.sdg_id > 0">
                        <img :src="sdg.logo" />
                    </div>
                    <div class="col-md-6" v-else>
                        <img src="https://res.cloudinary.com/changa/image/upload/c_scale,f_auto,q_auto,w_520/v1649771541/web/Africalogo.png" />
                    </div>
                    <div class="col-md-6 p_sdg">
                        <p>{{ sdg.campaigns.toLocaleString("en") }} Campaigns</p>
                        <p>KES {{ sdg.amount.toLocaleString("en") }} Raised</p>
                        <p>{{ sdg.donations.toLocaleString("en") }} Donations</p>
                        <p>{{ sdg.supporters.toLocaleString("en") }} Supporters</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { Head } from "@vueuse/head";
import { lazyLoadComponent } from "../utils/lazy-load-components";
import loader from "../components/loader.vue";

export default {
  setup() {
    return {};
  },
  components: {},
  data() {
    return {
      my_sdg: [],
    };
  },

  mounted() {
    this.getSDGData(this.fund_list);
  },

  methods: {
    async getSDGData(fund_list) {
      fetch(process.env.VUE_APP_ROOT_API + `/service/affiliate/tracker`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          referralcode: this.$route.params.refID,
          campaigns: fund_list,
          sdg: 1
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            this.my_sdg = data.sdg;
          } else {
            this.affErr = 0;
            if (data.message) {
              this.affiliateError = data.message;
            } else {
              this.affiliateError = "Error!! No campaigns.";
            }
          }
        });
    },
  },
  props: {
    fund_list: {
        default: "",
        type: String,
    }
  }
};
</script>

<style scoped>
.p_sdg p{
    margin-bottom: 0 !important;
}
.p_sdg { margin: 0; padding: 0;}
</style>